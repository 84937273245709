"use client";

import { useSite } from "@/contexts/site/site.context";
import type { TranslationProps } from "../translation.types";

/**
 * You can pass either individual translations or a model with translations.
 * Ex. useTranslation({ en: "Hello", es: "Hola" ... }) or useTranslation({ model: { en: "Hello", es: "Hola" ... } )
 */
export function useTranslation<T>(props: TranslationProps) {
  const { locale } = useSite();
  return "model" in props ? (props.model[locale] as T) : (props[locale] as T);
}
