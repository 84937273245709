"use client";

import NextImage from "next/image";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { Link } from "@/components/Link/Link.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import { imageLoader } from "@/utilities/image-loader";
type ArticleTeaserListItemProps = {
  readonly model: ArticleTeaserModel;
  readonly position?: string;
};
export function ArticleTeaserListItem({
  model: {
    content,
    requiresSubscription
  },
  position
}: ArticleTeaserListItemProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  return <article className="grid grid-cols-5 gap-x-1 sm:gap-x-2 lg:grid-cols-12 lg:gap-x-2.5" data-k5a-pos={position} data-sentry-component="ArticleTeaserListItem" data-sentry-source-file="ArticleTeaserListItem.component.tsx">
      <div className="relative col-span-2 aspect-4/3 w-full lg:col-span-3">
        {content.supportingImage !== null ? <Link className="relative block h-full w-full" href={content.targetUrl} position="image">
            <NextImage alt={content.supportingImage.alternativeText} className="bg-whisper object-cover" loader={imageLoader(serverUrl)} sizes={`(max-width: ${tailwindScreenSize.md}) 40vw, 230px`} src={content.supportingImage.aspectRatio4By3} fill />
          </Link> : null}
      </div>
      <div className="col-span-3 lg:col-span-9">
        <Link className="mb-2 block hover:underline" href={content.targetUrl} position="heading" data-sentry-element="Link" data-sentry-source-file="ArticleTeaserListItem.component.tsx">
          <h1 className="hyphens-manual font-heading text-xl font-theme-heading-weight lg:text-2xl" dangerouslySetInnerHTML={{
          __html: content.headline.html
        }} />
        </Link>

        {content.lead !== null ? <Link className="block text-base leading-snug" href={content.targetUrl} position="lead">
            <HtmlContent className="text-dim transition-colors duration-150 hover:text-black" html={content.lead.html} />
          </Link> : null}

        {requiresSubscription ? <RequiresSubscriptionLabel className="text-primary lg:mt-3" /> : null}
      </div>
    </article>;
}